

/** Renders a loading page */

function LoadingPage(){

  return(
    <h3 className='LoadingPage'>Loading...</h3>
  )
}



export default LoadingPage;